<template>
  <body class="test">
    <div class="m">
      <div class="container-rb">
        <div class="container-iu">
          <img :src="'/images/logo2.png'" class="img-u" />
        </div>
        <div class="description-txt">
          <h1 class="txt-rs">¿Qué tipo de universitario eres?</h1>
        </div>
        <div class="container-us">
          <router-link to="/preuniversitario" class="btn-a">
            <div class="img-a">
              <img :src="'/images/exuniversitario.png'" class="a" />
            </div>
            <div class="container-pre">
              <p class="pre1">PRE-UNIVERSITARIO</p>
            </div>
            <div class="container-pre">
              <p class="student-references">Cursando Bachillerato</p>
            </div>
          </router-link>
          <router-link to="/universitario" class="btn-a">
            <div class="img-a">
              <img :src="'/images/exuniversitario.png'" class="a" />
            </div>
            <div class="container-pre">
              <p class="pre1">UNIVERSITARIO</p>
            </div>
            <div class="container-pre">
              <p class="student-references">Estudiante cursando Universidad</p>
            </div>
          </router-link>
          <router-link class="btn-profesional" to="/exuniversitario">
            <div class="img-a mb-2">
              <img :src="'/images/preuniversitario.png'" class="a" />
            </div>
            <div class="container-pre">
              <p class="text-profesional">Profesionista</p>
            </div>
            <div class="container-pre">
              <p class="student-references">Recién egresado o con experiencia laboral</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </body>
</template>

<style land="scss" scoped>
@import "../styles/selectstudent.scss";
</style>
